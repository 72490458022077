/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
try {
    window.$ = window.jQuery = require("jquery");
    window.bootstrap = require("bootstrap5/dist/js/bootstrap.bundle");
} catch (e) { }

let _window = $(window),
    body = $('body');

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        "X-Requested-With": "XMLHttpRequest",
    },
});

window.onSubmitCaptcha = function (token) {
    $("#reCaptchaForm").trigger("submit");
};

$("#reCaptchaForm")
    .on("submit", function (event) {
        if ($(this).find(":invalid").length) {
            event.preventDefault();
            $(this).find(":invalid").first().focus();
        } else {
            $(this)
                .find("button[type=submit]")
                .prop("disabled", true)
                .addClass('pe-none opacity-50')
                .append('<i class="fad fa-spinner-third fa-spin ms-1"></i>');
        }
    });

window.onSubmitCaptcha2 = function (token) {
    $("#reCaptchaForm2").trigger("submit");
};

$("#reCaptchaForm2")
    .on("submit", function (event) {
        if ($(this).find(":invalid").length) {
            event.preventDefault();
            $(this).find(":invalid").first().focus();
        } else {
            $(this)
                .find("button[type=submit]")
                .prop("disabled", true)
                .addClass('pe-none opacity-50')
                .append('<i class="fad fa-spinner-third fa-spin ms-1"></i>');
        }
    });

$("input[type=tel], input[inputmode=numeric]").on("input", function (e) {
    $(this).val(
        $(this)
            .val()
            .replace(/[^0-9]/g, "")
    );
});

$("form")
    .not("#reCaptchaForm")
    .not("#reCaptchaForm2")
    .not("#search-box")
    .on("submit", function (event) {
        if ($(this).find(":invalid").length) {
            event.preventDefault();
            $(this).find(":invalid").first().focus();
        } else {
            $(this)
                .find("button[type=submit]")
                .prop("disabled", true)
                .append('<i class="fad fa-spinner-third fa-spin ms-1"></i>');
        }
    });

$("form#search-box")
    .on("submit", function (event) {
        if ($(this).find(":invalid").length) {
            event.preventDefault();
            $(this).find(":invalid").first().focus();
        } else {
            $(this).find("button[type=submit]").prop("disabled", true);
        }
    });

$('#logout').on('click', function () {
    $('#logout-form').submit();
});

$('[data-loading]').on('click', (e) => {
    $(e.currentTarget).prop('disabled', true).addClass('pe-none').append('<i class="fad fa-spinner-third fa-spin ms-1"></i>');
});

/*      menu dropdown arrow
/*----------------------------------------*/

let megaMenuItem = $('.mega-menu > li'),
    subMenuDropdown = $('.sub-menu > .dropdown'),
    sidebarMenuSubMenu = $('.sidebar-menu .sub-menu');

function menuDropdownArrow(parentSelector, childSelector) {
    parentSelector.each(function () {
        let self = $(this);

        if (self.children().length > 1) {
            self.children(`${childSelector}`).append('<i class="far fa-chevron-down"></i>');
        }
    });
}

menuDropdownArrow(subMenuDropdown, 'a');
menuDropdownArrow(megaMenuItem, '.menu-item');

//dashboard menu
$('.account-sidebar li').each((i, li) => {
    if ($(li).children('ul').length > 0) {
        $(li).addClass('parent');
    }
});

let accountSidebarLink = $('.account-sidebar li.parent > a');
let accountParentUls = $('.account-sidebar li.active').parentsUntil('.account-sidebar', 'ul');

accountSidebarLink.after('<i class="far fa-chevron-down"></i>');
accountParentUls.show().siblings('i').addClass('open');

$('.account-sidebar li > i, .account-sidebar li > .link').on('click', (e) => {
    $(e.currentTarget).siblings('i').toggleClass('open').siblings('ul').slideToggle(300);
})

/*      overlay
/*----------------------------------------*/

$('.number-only').on('keyup', function () {
    let new_input = $(this).val().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    $(this).val(new_input);
});

function initMobileMenu() {
    document.addEventListener(
        "DOMContentLoaded", () => {
            const menu = new MmenuLight(
                document.querySelector("#mobileMenu"),
                "(max-width: 1160px)",
            );

            $("#mobileMenu").addClass("mm");

            const navigator = menu.navigation({
                title: 'Menu',
                selected: "active",
            });

            const drawer = menu.offcanvas({
                position: "right",
                move: true,
                blockPage: true,
            });

            $('a[href="#openMenu"]')
                .on('click', (evnt) => {
                    evnt.preventDefault();
                    drawer.open();
                });
        }
    );
}

initMobileMenu();


window.addEventListener("pageshow", function (event) {
    var historyTraversal = event.persisted ||
        (typeof window.performance != "undefined" &&
            window.performance.navigation.type === 2);
    if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
    }
});


$('#view-more').on('click', function () {
    var $this = $(this);
    if ($this.html().includes('View more')) {
        $this.html('View less <i class="fas fa-chevron-up ms-2 fs-13"></i>');
    } else {
        $this.html('View more <i class="fas fa-chevron-down ms-2 fs-13"></i>');
    }
});

Fancybox.bind("[data-fancybox]", {
    wheel: "slide",
    hideScrollbar: false,
    Carousel: {
        infinite: false
    },
});

$('.camera-icon').on('click', function () {
    let hash = $(this).data('hash');

    let images = [];

    // Get all images with the specific class and add to the array
    $('.vehicle-gallery-' + hash).each(function () {
        let src = $(this).attr('src');
        let caption = $(this).attr('alt');

        images.push({
            src: src,
            caption: caption
        });
    });

    // Open Fancybox with the gathered images
    Fancybox.show(images, {
        wheel: "slide",
        // trapFocus: true,
        // backdropClick: true,
        Carousel: {
            infinite: false
        },
        caption: function (instance, slide) {
            return slide.caption;
        },
    });
});

$('.video-icon').on('click', function () {
    let videoUrl = $(this).data('video-url');

    Fancybox.show([{
        src: videoUrl,
        type: 'iframe'
    }], {
        trapFocus: true,
        backdropClick: false,
        caption: function (instance, slide) {
            return "Source: " + videoUrl;
        }
    });
});

$('#input-sort_by').on('change', function () {
    $('.hidden-sort_by').val($(this).val());

    $('#search-box').submit();
});

$('#input-per_page').on('change', function () {
    $('.hidden-per_page').val($(this).val());

    $('#search-box').submit();
});

$('#input-port_id').on('change', function () {
    $('.hidden-port_id').val($(this).val());
});

$('#toggle-inspection').on('change', function () {
    $('.hidden-inspection').val($(this).is(':checked') ? '1' : '0');
});

$('#toggle-insurance').on('change', function () {
    $('.hidden-insurance').val($(this).is(':checked') ? '1' : '0');
});

$('.hidden-option').on('click', function () {
    let vehicle_id = $(this).data('id');

    $('.hidden-option-' + vehicle_id).hide();
    $('.show-option-' + vehicle_id).show();
});

$('.show-option').on('click', function () {
    let vehicle_id = $(this).data('id');

    $('.show-option-' + vehicle_id).hide();

    $('.option-' + vehicle_id).show();
    $('.hide-option-' + vehicle_id).show();
});

$('.hide-option').on('click', function () {
    let vehicle_id = $(this).data('id');

    $('.show-option-' + vehicle_id).show();

    $('.option-' + vehicle_id).hide();
    $('.hide-option-' + vehicle_id).hide();
});

$('a[href="#"]').on('click', function (e) {
    e.preventDefault();
});

function toggleSearchBox() {
    let hasValue = false;

    $('#input-keyword, #input-make_id, #input-model_id, #input-gear, #input-min_year, #input-max_year').each(function () {
        if ($(this).val()) {
            hasValue = true;
            return false; // Exit the loop early if any input has a value
        }
    });

    if (hasValue) {
        $('.search-right-inner-right').removeClass('disabled');
        $('.search-btn').prop('type', 'submit');
    } else {
        $('.search-right-inner-right').addClass('disabled');
        $('.search-btn').prop('type', 'button');
    }
}

$('#input-keyword, #input-make_id, #input-model_id, #input-gear, #input-min_year, #input-max_year').on('input change', toggleSearchBox);

// Initial check in case there are already values present
toggleSearchBox();

$('#search-box').on('submit', function (event) {
    if ($('.search-right-inner-right').hasClass('disabled')) {
        event.preventDefault();
    }
});

$('#search-box input').on('keypress', function (event) {
    if (event.key === 'Enter' && $('.search-right-inner-right').hasClass('disabled')) {
        event.preventDefault();
    }
});

$('.horizontal-megamenu.mega-menu .dropdown').on('mouseenter', function () {
    var $subMenu = $(this).children('.sub-menu');
    var $arrow = $(this).find('> a > i'); // Use `find` to ensure correct targeting

    var rect = $subMenu[0].getBoundingClientRect();

    if (rect.right > $(window).width()) {
        $subMenu.css({
            'left': '0',
            'top': 'unset',
            'bottom': '-100%'
        });

        $arrow.css({
            'transform': 'translateY(-50%) rotate(0deg)'
        });
    }
});


